/** ***************************************************************************
 * Atoms / Cursor
 *************************************************************************** */

const CustomCursor = require('custom-cursor.js').default;

class LemuriaCursor {
  constructor(element) {
    // Following options represent the defaults
    const options = {
      // Whether or not the true cursor should be hidden when the custom cursor is initialized
      hideTrueCursor: false,

      // Array of DOM elements/selector strings that add interactions on hover
      focusElements: [
        {
          elements: '.lm-u-button',
          focusClass: 'cursor--default',
        },
        // {
        //   elements: 'a',
        //   focusClass: 'cursor--focused',
        // },
        {
          elements: '.default-cursor',
          focusClass: 'cursor--default',
        },
        {
          elements: '.custom-cursor',
          mouseenter: () => {
            // Run in addition to adding the focusClass
            customCursor.hideTrueCursor();
          },
          mouseleave: () => {
            // Run in addition to removing the focusClass
            customCursor.unhideTrueCursor();
          },
        },
        {
          elements: '.big-cursor',
          focusClass: 'cursor--focused-lg',
        },
        {
          elements: '.extra-big-cursor',
          focusClass: 'cursor--focused-xl',
        },
        {
          elements: '.pulsing-oval',
          focusClass: 'cursor--pulsing-oval',
        },
        {
          elements: '.lm-c-video_poster',
          focusClass: 'cursor--focused-video',
        },
        {
          elements: '.lm-c-pre_footer',
          focusClass: 'cursor--pre_footer',
        },
        {
          elements: '.backdrop',
          focusClass: 'cursor--backdrop',
        },
        'a',
      ],

      // Class applied when the true cursor is hovering over a focusElement
      focusClass: 'cursor--focused',
    };

    const customCursor = new CustomCursor(element, options)
      .setPosition(-200, -200)
      .initialize();
  }

  destroy() {}
}

window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-cursor .cursor-shape';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmCursor = new LemuriaCursor(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmCursor) {
        element.lmCursor.destroy();
        element.lmCursor = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  setTimeout(() => initialize());
});

export { LemuriaCursor };
