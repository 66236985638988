/** ***************************************************************************
 * Website / Organisms / Modal
 *************************************************************************** */

import MicroModal from 'micromodal';
import { VideoComponent } from '../../02-molecules/video/video';

class PbModalComponent {
  constructor(element, contextId) {
    this.element = element;
    this.element.setAttribute('id', `pb-modal-${contextId}`);
    this.videoComponent = this.element.querySelector('.lm-c-video');
    this.backdrop = this.element.querySelector('.backdrop');

    MicroModal.init();

    this.setUpEventListeners();
  }

  open(videoSrc) {
    MicroModal.show(this.element.id, {
      // onShow: (modal) => console.info(`${modal.id} is shown`), // [1]
      onClose: (modal) => {
        this.onClose();
      }, // [2]
      openTrigger: 'pb-micromodal-open', // [3]
      closeTrigger: 'pb-micromodal-close', // [4]
      openClass: 'is-open', // [5]
      disableScroll: true, // [6]
      disableFocus: false, // [7]
      awaitOpenAnimation: false, // [8]
      awaitCloseAnimation: false, // [9]
      debugMode: true, // [10]
    });

    if (this.videoComponent.lmVideoComponent) {
      this.videoComponent.lmVideoComponent.play();
    } else {
      this.videoComponent.lmVideoComponent = new VideoComponent(
        this.videoComponent,
        videoSrc,
        true
      );
    }

    this.backdrop.focus();
  }

  close() {
    MicroModal.close(this.element.id);
  }

  setUpEventListeners() {
    document.addEventListener('keyup', this.keyPress.bind(this));
  }

  keyPress(e) {
    if (e.key === 'Escape') {
      this.close();
    }
  }

  onClose() {
    this.videoComponent.lmVideoComponent.pause();
  }
}

export { PbModalComponent as default };
