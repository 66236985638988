/******************************************************************************
 * Common / Molecules / Video
 *****************************************************************************/

import Player from '@vimeo/player';
import videojs from 'video.js';
import 'videojs-youtube';

class VideoComponent {
  constructor(element, videoSrc, forceAutoPlay = false) {
    this.element = element;
    this.videoSrc = videoSrc;
    this.forceAutoPlay = forceAutoPlay;
    this.source = this.parseVideo(this.videoSrc).type;
    this.customPlayerWidget = this.element.querySelector('video-js');
    this.vimeoPlayerWidget = this.element.querySelector('#vimeo-video');
    this.videoOverlay = this.element.querySelector('.video-overlay');
    this.hasPoster = this.element.querySelector('.video-overlay.has-poster');
    this.posterPlaceholder = this.element.querySelector('.video-overlay > img');
    this.playButton = this.element.querySelector('.lm-c-button-play');

    if (this.source === 'vimeo') {
      this.customPlayerWidget.style.display = 'none';

      if (this.hasPoster) {
        this.videoOverlay.onclick = (event) => {
          event.preventDefault();
          this.videoOverlay.classList.add('is-loading');

          this.initializeVimeoPlayer();
          this.vimeoPlayer.ready().then(() => {
            this.vimeoPlayerWidget.style.display = 'block';
            this.videoOverlay.style.display = 'none';
            this.videoOverlay.classList.remove('is-loading');
          });
        };
      } else {
        this.videoOverlay.classList.add('is-loading');

        this.initializeVimeoPlayer();
        this.vimeoPlayer.ready().then(() => {
          this.vimeoPlayerWidget.style.display = 'block';
          this.videoOverlay.classList.remove('is-loading');
          this.playButton.style.display = 'flex';
          if (this.forceAutoPlay) {
            this.videoOverlay.click();
          }
        });

        this.videoOverlay.onclick = (event) => {
          event.preventDefault();
          this.videoOverlay.style.display = 'none';
          this.vimeoPlayer.play();
        };
      }
    } else if (this.source === 'youtube') {
      const urlToReach = this.videoSrc.split('&')[0];
      this.vimeoPlayerWidget.style.display = 'none';
      if (this.posterPlaceholder) {
        this.posterPlaceholder.style.display = 'none';
      }

      this.videoOverlay.classList.add('is-loading');

      this.customPlayer = videojs(this.customPlayerWidget, {
        controls: true,
        autoplay: this.forceAutoPlay || false,
        preload: 'auto',
        fluid: true,
        techOrder: [this.source],
        sources: [
          {
            type: `video/${this.source}`,
            src: urlToReach,
          },
        ],
        poster: this.hasPoster
          ? this.videoOverlay.getAttribute('data-poster')
          : null,
        // youtube: { iv_load_policy: 3 },
        // controlBar: {
        //   playToggle: false,
        //   captionsButton: false,
        //   chaptersButton: false,
        //   subtitlesButton: false,
        //   remainingTimeDisplay: false,
        //   progressControl: {
        //     seekBar: true
        //   },
        //   fullscreenToggle: false,
        //   playbackRateMenuButton: false,
        // },
      });

      this.videoOverlay.onclick = (event) => {
        event.preventDefault();
        this.customPlayer.play();
      };

      this.customPlayer.on('play', () => {
        // setTimeout(() => {
        this.customPlayerWidget.style.zIndex = '99';
        // this.videoOverlay.style.display = 'none';
        // }, 250);
      });

      this.customPlayer.ready(() => {
        this.videoOverlay.classList.remove('is-loading');
        this.playButton.style.display = 'flex';
      });
    }
  }

  /**
   * Currently checking for YouTube and Vimeo source ONLY
   */
  parseVideo(url) {
    // - Supported YouTube URL formats:
    //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
    //   - http://youtu.be/My2FRPA3Gf8
    //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
    // - Supported Vimeo URL formats:
    //   - http://vimeo.com/25451551
    //   - http://player.vimeo.com/video/25451551
    // - Also supports relative URLs:
    //   - //player.vimeo.com/video/25451551

    url.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    );

    if (RegExp.$3.indexOf('youtu') > -1) {
      var type = 'youtube';
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
      var type = 'vimeo';
    }

    return {
      type: type,
      id: RegExp.$6,
    };
  }

  initializeVimeoPlayer() {
    // Utilizzando espressioni regolari per estrarre l'ID del video
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/|moogaloop\.swf\?clip_id=)?(\d+)(?:.*)/;
    const match = this.videoSrc.match(regex);

    // Se corrisponde, ottieni l'ID del video
    const videoId = match ? match[1] : null;

    const options = {
      id: videoId,
      autoplay: this.hasPoster ? true : false,
    };

    this.vimeoPlayer = new Player(this.vimeoPlayerWidget, options);
  }

  play() {
    if (this.customPlayer) {
      this.customPlayer.play();
    }
    if (this.vimeoPlayer) {
      this.vimeoPlayer.play();
    }
  }

  pause() {
    if (this.customPlayer) {
      this.customPlayer.pause();
    }
    if (this.vimeoPlayer) {
      this.vimeoPlayer.pause();
    }
  }

  destroy() {
    if (this.customPlayer) {
      this.customPlayer.dispose();
    }
    if (this.vimeoPlayer) {
      this.vimeoPlayer.destroy();
    }
  }
}

// window.addEventListener('lemuria:init', () => {
//   const selector = '.lm-c-video';

//   function initialize() {
//     document.querySelectorAll(selector).forEach((element) => {
//       // https://www.youtube.com/watch?v=dQw4w9WgXcQ
//       // https://player.vimeo.com/video/81150158?h=713880bb81
//       // https://www.youtube.com/watch?v=ukk2p84WLaE&list=FLon7YcbdyhD8D0VswoMD8Ew&index=72&ab_channel=FondazioneGaber
//       element.lmVideoComponent = new VideoComponent(
//         element,
//         'https://player.vimeo.com/video/81150158?h=713880bb81'
//       );
//     });
//   }

//   function destroy() {
//     document.querySelectorAll(selector).forEach((element) => {
//       if (element.lmVideoComponent) {
//         element.lmVideoComponent.destroy();
//       }
//     });
//   }

//   window.LEMURIA.register(selector, initialize, destroy);

//   initialize();
// });

export { VideoComponent };
